import React, { useState } from 'react';
import axios from 'axios';

const UserTable = ({ users, fetchUsers }) => {
  const [editingUserId, setEditingUserId] = useState(null);
  const [editName, setEditName] = useState('');
  const [editAge, setEditAge] = useState('');
  const [editWork, setEditWork] = useState('');
  const [editMobile, setEditMobile] = useState('');

  const handleDelete = async (userId) => {
    try {
      await axios.delete(`http://127.0.0.1:5000/delete_user/${userId}`);
      fetchUsers();
    } catch (error) {
      console.error('There was an error deleting the user!', error);
    }
  };

  const handleEdit = (user) => {
    setEditingUserId(user[0]);
    setEditName(user[1]);
    setEditAge(user[2]);
    setEditWork(user[3]);
    setEditMobile(user[4]);
  };

  const handleSave = async (userId) => {
    try {
      await axios.patch(`http://127.0.0.1:5000/update_user/${userId}`, {
        name: editName,
        age: editAge,
        work: editWork,
        mobile: editMobile,
      });
      setEditingUserId(null);
      fetchUsers();
    } catch (error) {
      console.error('There was an error updating the user!', error);
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="px-4 py-2 border">ID</th>
            <th className="px-4 py-2 border">Name</th>
            <th className="px-4 py-2 border">Age</th>
            <th className="px-4 py-2 border">Work</th>
            <th className="px-4 py-2 border">Mobile</th>
            <th className="px-4 py-2 border">Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user[0]} className="hover:bg-gray-100">
              <td className="px-4 py-2 border">{user[0]}</td>
              <td className="px-4 py-2 border">
                {editingUserId === user[0] ? (
                  <input
                    type="text"
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                    className="px-2 py-1 border rounded"
                  />
                ) : (
                  user[1]
                )}
              </td>
              <td className="px-4 py-2 border">
                {editingUserId === user[0] ? (
                  <input
                    type="number"
                    value={editAge}
                    onChange={(e) => setEditAge(e.target.value)}
                    className="px-2 py-1 border rounded"
                  />
                ) : (
                  user[2]
                )}
              </td>
              <td className="px-4 py-2 border">
                {editingUserId === user[0] ? (
                  <input
                    type="text"
                    value={editWork}
                    onChange={(e) => setEditWork(e.target.value)}
                    className="px-2 py-1 border rounded"
                  />
                ) : (
                  user[3]
                )}
              </td>
              <td className="px-4 py-2 border">
                {editingUserId === user[0] ? (
                  <input
                    type="text"
                    value={editMobile}
                    onChange={(e) => setEditMobile(e.target.value)}
                    className="px-2 py-1 border rounded"
                  />
                ) : (
                  user[4]
                )}
              </td>
              <td className="px-4 py-2 border">
                {editingUserId === user[0] ? (
                  <button
                    onClick={() => handleSave(user[0])}
                    className="px-3 py-1 text-white bg-blue-500 rounded hover:bg-blue-700"
                  >
                    Save
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => handleEdit(user)}
                      className="px-3 py-1 mr-2 text-white bg-yellow-500 rounded hover:bg-yellow-700"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(user[0])}
                      className="px-3 py-1 text-white bg-red-500 rounded hover:bg-red-700"
                    >
                      Delete
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
